//!you may need to change record.key to record.key
import { Popconfirm, Table, Form, Input, Tag } from "antd";
import { useReducer, useEffect, useState } from "react";
import axios from "axios";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from "./useWindowSize";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState, useAuthDispatch } from "../GlobalContext.jsx";
import { BsMessenger, BsWhatsapp } from "react-icons/bs";

function useTable(
  tableFor,
  nested,
  sessionID,
  dependency1,
  dependency2,
  params,
  ifExternal,
  externalData,
  refetch,
  setReFetch,
  handelSelectionItems
) {
  const AuthState = useAuthState();
  const AuthDispatch = useAuthDispatch();

  const navigate = useNavigate();
  const { width } = useWindowSize();
  useEffect(() => {
    if (width && width < 1000) {
      dispatch({ type: "setSmallWindow", payload: true });
    } else {
      dispatch({ type: "setSmallWindow", payload: false });
    }
  }, [width]);

  let columns;
  let api;

  switch (tableFor) {
    case "codes":
      api = process.env.REACT_APP_ALL_CODES_API;
      break;
    case "pointsCodes":
      api = process.env.REACT_APP_ALL_POINTS_CODES_API;
      break;

    case "activationCodes":
      api = process.env.REACT_APP_ALL_ACTIVATION_CODES_API;
      break;
    case "offersCodes":
      api = process.env.REACT_APP_ALL_OFFERS_CODES_API;
      break;
    case "vodafoneCash":
      api = process.env.REACT_APP_ADMIN_AVODAFONE_CASH_GET_CODES_API;
      break;
    case "groups":
      api = process.env.REACT_APP_ALL_GROUPS_API;
      break;
    case "QuestionBank":
      api = process.env.REACT_APP_ALL_CATEGORIES_API;
      break;
    case "attendance":
      api = "https://groups-api.onrender.com/data";
      break;

    case "assistants":
      api = process.env.REACT_APP_ALL_ASSISTANTS_API;
      break;
    case "students":
      api = `${process.env.REACT_APP_ADMIN_STUDENT_TAB_API}${params}`;
      break;
    case "deletedStudents":
      api = process.env.REACT_APP_ALL_DELETED_STUDENTS_API;
      break;
    case "sets":
      api = `${process.env.REACT_APP_ADMIN_SESSION_ALL_SETS_API}${params}`;
      break;
    case "examSets":
      api = `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_SETS_API}${params}`;
      break;
    case "viewCourse":
      api = `${process.env.REACT_APP_ADMIN_COURSE_VIEW_TABLE_API}/${params}`;
      break;
    case "viewCourseLateStudents":
      api = `${process.env.REACT_APP_ADMIN_COURSE_VIEW_TABLE_LATE_STUDENTS_API}/${params}`;
      break;
    case "adminViewStudentAsignments":
      api = `${process.env.REACT_APP_ADMIN_COURSE_VIEW_TABLE_GET_ASSIGNMENTS_API}/${params}`;
      break;
    case "viewAllStudentsAssignments":
      api = `${process.env.REACT_APP_ADMIN_COURSE_VIEW_ALL_STUDENTS_ASSGINMENTS_API}/${params}`;
      break;
    case "viewAllStudentsReports":
      api = `${process.env.REACT_APP_ADMIN_COURSE_VIEW_ALL_STUDENTS_REPORTS_API}/${params}`;
      break;
    // case "viewAllLateStudents":
    //   api = `${process.env.REACT_APP_ADMIN_COURSE_VIEW_ALL_LATE_STUDENTS_API}/${params?.academiclevel}/${params?.days}`;
    //   break;

    case "analytics":
      api = process.env.REACT_APP_ADMIN_ANALYTICS_All_API;
      break;
    case "viewStudentExam":
      api = `${process.env.REACT_APP_ADMIN_VIEW_STUDENTS_EXAM_API}/${params}`;
      break;
    case "logs":
      api = `${process.env.REACT_APP_STUDENT_COURSE_VIEW_LOG_API}/${params}`;
      break;
    case "purchaseHistoryStudentView":
      api = `${process.env.REACT_APP_STUDENT_COURSE_VIEW_PURCHASE_API}/${params}`;
      break;
    case "trialExam":
      api = `${process.env.REACT_APP_STUDENT_COURSE_VIEW_EXAMS_API}/${params}`;
      break;
    default:
      api = "https://students-api-g0pp.onrender.com/data";
      break;
  }

  const initialState = {
    data: [],
    forFilterData: [],
    loading: false,
    editRowKey: "",
    sortingOptions: {},
    query: "",
    columnQuery: "",
    queriedColumn: "",
    filteredInfo: {},
    selectedRows: [],
    smallWindow: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "setData": {
        return {
          ...state,
          data: action.payload,
        };
      }
      case "setForFilterData": {
        return {
          ...state,
          forFilterData: action.payload,
        };
      }

      case "SetLoading": {
        return {
          ...state,
          loading: action.payload,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: !state.showSidebar,
        };
      }

      case "SetEditRowKey": {
        return {
          ...state,
          editRowKey: action.payload,
        };
      }
      case "SetSortingOptions": {
        return {
          ...state,
          sortingOptions: action.payload,
        };
      }
      case "SetQuery": {
        return {
          ...state,
          query: action.payload,
        };
      }
      case "SetColumnQuery": {
        return {
          ...state,
          columnQuery: action.payload,
        };
      }
      case "SetQueriedColumn": {
        return {
          ...state,
          queriedColumn: action.payload,
        };
      }
      case "SetFilteredInfo": {
        return {
          ...state,
          filteredInfo: action.payload,
        };
      }
      case "setSelectedRows": {
        return {
          ...state,
          selectedRows: action.payload,
        };
      }
      case "setSmallWindow": {
        return {
          ...state,
          smallWindow: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  async function fetchData() {
    if (ifExternal) {
      dispatch({ type: "setData", payload: externalData });
      dispatch({ type: "setForFilterData", payload: externalData });

      dispatch({ type: "SetLoading", payload: false });
    } else {
      try {
        const res = await axios.get(api, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              AuthState.userData.student_token || AuthState.userData.admin_token
            }`,
            /* Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`, */
            //"Content-Type": "multipart/form-data",
          },
          //withCredentials: true,
        });
        const data = await res.data;
        dispatch({ type: "setData", payload: data.data });
        dispatch({ type: "setForFilterData", payload: data.data });
        dispatch({ type: "SetLoading", payload: false });
      } catch (error) {
        toast.error(
          error?.response?.data?.message || "Something Wrong has happened!"
        );

        dispatch({ type: "SetLoading", payload: false });
        //!need to handle the case of delete the last item in the table and refetching again, so the refetch request will response with error no data and the last data will stay in the page but it must be removed whereas the item has deleted
        dispatch({ type: "setData", payload: [] });
      }
    }
  }

  useEffect(() => {
    dispatch({ type: "SetLoading", payload: true });
    fetchData();
  }, [dependency1, dependency2]);

  const [form] = Form.useForm();

  async function handleDelete(record) {
    let deleteApi;
    switch (tableFor) {
      case "codes":
        deleteApi = process.env.REACT_APP_DELETE_CODE_API;
        break;
      case "pointsCodes":
        deleteApi = process.env.REACT_APP_DELETE_POINTS_CODE_API;
        break;
      case "activationCodes":
        deleteApi = process.env.REACT_APP_DELETE_ACTIVATION_CODE_API;
        break;
      case "offersCodes":
        deleteApi = process.env.REACT_APP_DELETE_OFFERS_CODE_API;
        break;
      case "vodafoneCash":
        deleteApi = process.env.REACT_APP_ADMIN_AVODAFONE_CASH_DELETE_CODE_API;
        break;
      case "groups":
        deleteApi = process.env.REACT_APP_DELETE_GROUP_API;
        break;
      case "QuestionBank":
        deleteApi = process.env.REACT_APP_DELETE_CATEGORY_API;
        break;
      case "attendance":
        deleteApi = "https://groups-api.onrender.com/data";
        break;
      case "session":
        deleteApi = "https://students-api-g0pp.onrender.com/data";
        break;
      case "assistants":
        deleteApi = process.env.REACT_APP_DELETE_ASSISTANT_API;
        break;
      case "students":
        deleteApi = process.env.REACT_APP_DELETE_STUDENT_API;
        break;
      case "deletedStudents":
        deleteApi = process.env.REACT_APP_ALL_DELETED_STUDENTS_DELETE_API;
        break;
      case "sets":
        deleteApi = process.env.REACT_APP_ADMIN_SESSION_DELETE_SET_API;
        break;
      case "examSets":
        deleteApi = process.env.REACT_APP_ADMIN_SESSION_DELETE_SET_API;
        break;
      case "OfferQuestions":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_COURSE_FROM_OFFER_API;
        break;
      case "viewCourse":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_IN_COURSE_API;
        break;
      case "viewStudentExam":
        deleteApi = process.env.REACT_APP_ADMIN_DELETE_STUDENT_TRIAL_EXAM_API;
        break;

      default:
        deleteApi = process.env.REACT_APP_DELETE_STUDENT_API;
        break;
    }

    const options = {
      url: deleteApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key || record.id },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);

        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Something Wrong has Happened!"
      );
    }
  }
  async function handleRestore(record) {
    const options = {
      url: process.env.REACT_APP_ALL_DELETED_STUDENTS_RESTORE_API,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        fetchData();
        AuthDispatch({
          type: "setRefetchIfAcceptOrDeleteRequest",
        });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Something Wrong has Happened!"
      );
    }
  }

  /**
//!we check if the current id stored in the editRowKey is equal to the record id to know which row is going to be updated
 */

  function isEditing(record) {
    return record.key === state.editRowKey;
  }

  /**
//!here we reset the editRowKey to indicate that no row is going to be update and hide the cancel and save buttons then show the edit and delete buttons again 
 */

  async function handleUpdateRequest(data) {
    let editApi;
    switch (tableFor) {
      case "codes":
        editApi = process.env.REACT_APP_EDIT_CODE_API;
        break;
      case "pointsCodes":
        editApi = process.env.REACT_APP_EDIT_POINTS_CODE_API;
        break;
      case "offersCodes":
        editApi = process.env.REACT_APP_EDIT_OFFERS_CODE_API;
        break;
      case "vodafoneCash":
        editApi = process.env.REACT_APP_ADMIN_AVODAFONE_CASH_UPDATE_CODE_API;
        break;
      case "groups":
        editApi = process.env.REACT_APP_EDIT_GROUP_API;
        break;
      case "QuestionBank":
        editApi = process.env.REACT_APP_EDIT_CATEGORY_API;
        break;
      case "attendance":
        editApi = "https://groups-api.onrender.com/data";
        break;
      case "session":
        editApi = "https://students-api-g0pp.onrender.com/data";
        break;
      case "assistants":
        editApi = process.env.REACT_APP_ALL_ASSISTANTS_API;
        break;
      case "sets":
        editApi = process.env.REACT_APP_ADMIN_SESSION_EDIT_SET_API;
        break;
      case "examSets":
        editApi = process.env.REACT_APP_ADMIN_SESSION_EDIT_SET_API;
        break;
      default:
        editApi = "";
        break;
    }

    const options = {
      url: editApi,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        //Authorization: `Bearer 37|zYs2BDqeMDjvczVd3EqEz1oKFpaU6Fy1U0ux0BwU`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    const res = await axios(options);
    return await res.data;
  }
  function handleCancelEdit() {
    dispatch({ type: "SetEditRowKey", payload: "" });
  }

  async function handleSaveEdit(record) {
    const key = record.key;
    try {
      const row = await form.validateFields();
      const res = await handleUpdateRequest({ ...row, id: key });
      toast.success(res.message);
      dispatch({ type: "SetEditRowKey", payload: "" });
      if (ifExternal) {
        setReFetch(!refetch);
      } else {
        fetchData();
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Please Fill the Required Fields"
      );
    }
  }

  function handleEdit(record) {
    form.setFieldsValue({
      /* firstname: "",
      username: "",

      password: "",
      governorate: "",
      city: "",
      academiclevel: "",
      school: "",
      activationcode: "", */
      ...record,
    });

    dispatch({ type: "SetEditRowKey", payload: record.key });
  }
  function handleResetSortFilterSearch(record) {
    dispatch({ type: "SetSortingOptions", payload: {} });
    dispatch({
      type: "SetFilteredInfo",
      payload: {},
    });
    dispatch({
      type: "SetQuery",
      payload: "",
    });
    fetchData();
  }

  function editableCell({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) {
    const input = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[{ required: true, message: `Please Add ${title}` }]}
          >
            {input}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  //!to save the sorting options and retrieve then reset it from reset button

  function handleTableChange(_, filter, sorter) {
    const { order, field } = sorter;
    dispatch({
      type: "SetSortingOptions",
      payload: { columnKey: field, order },
    });
    dispatch({
      type: "SetFilteredInfo",
      payload: filter,
    });
  }

  function handleGlobalSearch(e) {
    const searchValue = e.target.value.toLowerCase(); // Get the search term and convert to lowercase

    // Dispatch to update the query state
    dispatch({ type: "SetQuery", payload: searchValue });

    // Filter the data dynamically
    const filteredData = state?.forFilterData?.filter((item) => {
      // Iterate over all properties of the object
      for (const key in item) {
        if (
          item[key] && // Check if the property exists
          item[key].toString().toLowerCase().includes(searchValue) // Check if the property value includes the search term
        ) {
          return true; // Include this item in the filtered results
        }
      }
      return false; // Exclude this item if no property matches the search term
    });

    // Dispatch to update the filtered data state
    dispatch({ type: "setData", payload: filteredData });
  }

  function handleColumnSearch(selectedKeys, confirm, dataIndex) {
    confirm({ closeDropdown: false });
    dispatch({ type: "SetColumnQuery", payload: selectedKeys[0] });
    dispatch({ type: "SetQueriedColumn", payload: dataIndex });
  }
  function handleColumnReset(clearFilters, confirm) {
    clearFilters();
    dispatch({ type: "SetColumnQuery", payload: "" });
    dispatch({ type: "SetQueriedColumn", payload: "" });
    confirm();
  }

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="flex h-40 w-52 flex-col justify-center gap-5 bg-bg p-4 ">
        <Input
          className="signin-inputs search-table-placeholder text- h-10 pl-4 placeholder:!text-base"
          autoFocus
          placeholder={`Search in ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleColumnSearch(selectedKeys, confirm, dataIndex);
            }
          }}
        />
        <div className="flex justify-center gap-5 ">
          <button
            onClick={() => handleColumnReset(clearFilters, confirm)}
            className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90"
          >
            Reset
          </button>
          <button
            onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
            className="w-20 rounded-2xl border-2 border-blue bg-blue py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-blue active:scale-90"
          >
            Search
          </button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <FontAwesomeIcon
        className={`${filtered ? "text-secondary" : "text-save"}`}
        icon={faMagnifyingGlass}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",

    render: (text) =>
      state.queriedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#f9b522", padding: 0 }}
          searchWords={[state.columnQuery]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  async function handleRetakeExam(record) {
    const options = {
      url: process.env.REACT_APP_ADMIN_RETAKE_TRIAL_EXAM_API,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  async function handleLogutStudent(record) {
    const options = {
      url: process.env.REACT_APP_LOGOUT_STUDENT_FROM_TABLE_API,
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          AuthState.userData.student_token || AuthState.userData.admin_token
        }`,
        "Content-Type": "multipart/form-data",
      },
      data: { id: record.key },
    };
    try {
      const res = await axios(options);
      const data = await res.data;
      if (data.status === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        if (ifExternal) {
          setReFetch(!refetch);
        } else {
          fetchData();
          if (tableFor === "students") {
            AuthDispatch({
              type: "setRefetchIfAcceptOrDeleteRequest",
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  //!------------------ Columns --------------------

  const codesColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "ID",
      dataIndex: "key",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.key || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },

    {
      title: "Code",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Code"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },
    {
      title: "Price",
      dataIndex: "price",
      filteredValue: state.filteredInfo.price || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("price", "Price"),
      editTable: true,
      width: 40,
      align: "center",
      sorter: (a, b) => a.price - b.price,
      sortOrder:
        state.sortingOptions.columnKey === "price" &&
        state.sortingOptions.order,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Created At"),
      width: 60,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 70,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_delete")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#ffffff"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Are you sure you want to delete?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_edit")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Are you sure you want to cancel?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-red-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const pointsCodesColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "ID",
      dataIndex: "key",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.key || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },

    {
      title: "Code",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Code"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },
    {
      title: "Points",
      dataIndex: "points",
      filteredValue: state.filteredInfo.points || null,

      editTable: true,
      width: 40,
      align: "center",
      sorter: (a, b) => a.points - b.points,
      sortOrder:
        state.sortingOptions.columnKey === "points" &&
        state.sortingOptions.order,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Created At"),
      width: 60,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 70,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_delete")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#ffffff"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Are you sure you want to delete?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_edit")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Are you sure you want to cancel?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-red-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const activationCodesColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "ID",
      dataIndex: "key",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },

    {
      title: "Code",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Code"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },

    {
      title: "Created At",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Created At"),
      width: 60,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 30,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("code_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#ffffff"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="Are you sure you want to delete?"
              >
                <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const offersCodesColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },
    {
      title: "ID",
      dataIndex: "key",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },

    {
      title: "Code",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,
      ...getColumnSearchProps("code", "Code"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },
    {
      title: "Points",
      dataIndex: "points",
      filteredValue: state.filteredInfo.points || null,

      ...getColumnSearchProps("points", "points"),
      editTable: true,
      width: 40,
      align: "center",
      sorter: (a, b) => a.points - b.points,
      sortOrder:
        state.sortingOptions.columnKey === "points" &&
        state.sortingOptions.order,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,

      ...getColumnSearchProps("stage", "stage"),
      editTable: true,
      width: 40,
      align: "center",
      sorter: (a, b) => a.stage - b.stage,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Created At"),
      width: 60,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 70,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_delete")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#ffffff"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Are you sure you want to delete?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_edit")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Are you sure you want to cancel?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-red-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const QuestionBankColumns = [
    {
      title: "Numbering",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "Numbering"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
      render: (_, record) => {
        return AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("questionbank_viewquestionbycategory")
          ) ? (
          <Link to={`${record.key}`}>{record.name}</Link>
        ) : (
          <p>{record.name}</p>
        );
      },
    },
    {
      title: "Stage",
      dataIndex: "stage",
      filteredValue: state.filteredInfo.stage || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("stage", "Stage"),
      editTable: true,
      width: 150,
      align: "center",
      sorter: (a, b) => a.stage - b.stage,
      sortOrder:
        state.sortingOptions.columnKey === "stage" &&
        state.sortingOptions.order,
    },
    {
      title: "easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("easy", "easy"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },

    {
      title: "medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("medium", "medium"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },

    {
      title: "hard",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("hard", "hard"),
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },
    {
      title:
        AuthState?.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState?.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? "Questions"
          : "",
      dataIndex: "",
      width:
        AuthState?.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState?.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("questionbank_addquestion")
        )
          ? 350
          : 0,
      align: "center",
      //fixed: state.smallWindow ? false : "right",
      render: (_, record) => {
        return (
          (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("questionbank_addquestion")
            )) && (
            <div className="m-0 flex  justify-center gap-10">
              <Link
                to={`/admin/question-bank/${record.key}/add-essay-question`}
                className="w-fit rounded-2xl border-2 border-accent bg-blue px-4 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
              >
                Add essay question
              </Link>
              <Link
                to={`/admin/question-bank/${record.key}/add-choose-question`}
                className="w-fit rounded-2xl border-2 border-accent bg-accent px-4 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
              >
                Add choose question
              </Link>
            </div>
          )
        );
      },
    },

    {
      title: "The number of essay questions",
      dataIndex: "n_essay",
      filteredValue: state.filteredInfo.n_essay || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("n_essay", "The number of essay questions"),
      width: 200,
      align: "center",
      sorter: (a, b) => a.n_essay - b.n_essay,
      sortOrder:
        state.sortingOptions.columnKey === "n_essay" &&
        state.sortingOptions.order,
    },
    {
      title: "Total",
      dataIndex: "total",
      filteredValue: state.filteredInfo.total || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("total", "Total"),
      width: 110,
      align: "center",
      sorter: (a, b) => a.total - b.total,
      sortOrder:
        state.sortingOptions.columnKey === "total" &&
        state.sortingOptions.order,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,
      editTable: false,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Created At"),
      width: 150,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_updatecategory")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("questionbank_deletecategory")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#ffffff"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Are you sure you want to delete?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm Cancel Editing"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-red-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const AttendanceColumns = [
    {
      title: "الترقيم",
      dataIndex: "key",
      editTable: false,
      width: 80,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.key || null,

      ...getColumnSearchProps("key", "الترقيم"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },
    {
      title: "اسم الجروب",
      dataIndex: "groupname",
      filteredValue: state.filteredInfo.groupname || null,

      ...getColumnSearchProps("groupname", "اسم الجروب"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.groupname.length - b.groupname.length,
      sortOrder:
        state.sortingOptions.columnKey === "groupname" &&
        state.sortingOptions.order,
    },

    {
      title: "الصف الدراسي",
      dataIndex: "academiclevel",
      width: 150,
      align: "center",
      editTable: true,
      sorter: (a, b) => a.academiclevel.length - b.academiclevel.length,
      sortOrder:
        state.sortingOptions.columnKey === "academiclevel" &&
        state.sortingOptions.order,
      filters: [
        { text: "الصف الأاول", value: "الصف الأاول" },
        { text: "الصف الثاني", value: "الصف الثاني" },
        { text: "الصف الثالث", value: "الصف الثالث" },
      ],
      filteredValue: state.filteredInfo.academiclevel || null,
      onFilter: (value, record) => String(record.academiclevel).includes(value),
      render: (tag) => {
        const color = tag.includes("First")
          ? "#c71f38"
          : tag.includes("Second")
          ? "#f9b522"
          : "#1890ff";
        return (
          <Tag color={color} key={tag}>
            {tag}
          </Tag>
        );
      },
    },
  ];
  const sessionColumns = [
    {
      title: "الترقيم",
      dataIndex: "id",
      editTable: false,
      width: 80,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      filteredValue: state.filteredInfo.id || null,

      ...getColumnSearchProps("id", "الترقيم"),
      sorter: (a, b) => a.id - b.id,
      sortOrder:
        state.sortingOptions.columnKey === "id" && state.sortingOptions.order,
    },
    {
      title: "الإسم",
      dataIndex: "firstname",
      filteredValue: state.filteredInfo.firstname || null,
      /**
      *  ellipsis: {
        showTitle: false,
      },
      render: (firstname) => (
        <Tooltip placement="topLeft" title={firstname}>
          {firstname}
        </Tooltip>
      ),
      */
      //responsive: ["lg"],
      ...getColumnSearchProps("firstname", "الإسم"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "right",
      align: "center",
      sorter: (a, b) => a.firstname.length - b.firstname.length,
      sortOrder:
        state.sortingOptions.columnKey === "firstname" &&
        state.sortingOptions.order,
    },

    {
      title: "واتساب",
      dataIndex: "whatsUp",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.whatsUp || null,

      ...getColumnSearchProps("whatsUp", "واتساب"),
      editTable: false,
      render: (_, record) => {
        return (
          <a
            className="w-[150px] rounded-2xl border-2 border-accent bg-accent px-3 py-0 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
            href={`https://wa.me/${record.whatsUp}`}
            rel="noreferrer"
          >
            أرسل رسالة{" "}
          </a>
        );
      },
    },

    {
      title: "رقم الوالدة",
      dataIndex: "fathermobilenumber",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.fathermobilenumber || null,

      ...getColumnSearchProps("fathermobilenumber", "رقم الوالدة"),
      editTable: false,
      render: (_, record) => {
        return (
          <a
            className="w-[150px] rounded-2xl border-2 border-accent bg-accent px-3 py-0 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
            href={`https://wa.me/${record.fathermobilenumber}`}
            rel="noreferrer"
          >
            أرسل رسالة{" "}
          </a>
        );
      },
    },
    {
      title: "تاريخ الحضور",
      dataIndex: "password",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.password || null,

      ...getColumnSearchProps("password", "تاريخ الحضور"),
      editTable: true,
    },
    {
      title: "درجة الإمتحان",
      dataIndex: "password",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.password || null,

      ...getColumnSearchProps("password", "درجة الإمتحان"),
      editTable: true,
    },
    {
      title: "درجة الواجب",
      dataIndex: "password",
      width: 130,
      align: "center",
      filteredValue: state.filteredInfo.password || null,

      ...getColumnSearchProps("password", "درجة الواجب"),
      editTable: true,
    },

    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleDelete(record)}
                  title="Are you sure you want to delete?"
                >
                  <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                    Delete
                  </button>
                </Popconfirm>

                <button
                  className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                  onClick={() => handleEdit(record)}
                >
                  Edit
                </button>
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm Cancel Editing"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-red-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  حفظ
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const studentsColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,
      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },

    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      fixed: state.smallWindow ? false : "left",

      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      filteredValue: state.filteredInfo.email || null,
      ...getColumnSearchProps("email", "E-mail"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.email.length - b.email.length,
      sortOrder:
        state.sortingOptions.columnKey === "email" &&
        state.sortingOptions.order,
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,
      ...getColumnSearchProps("mobile", "Phone Number"),
      editTable: false,
    },
    {
      title: "Parent ID",
      dataIndex: "parent_id",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.parent_id || null,
      editTable: false,
    },
    {
      title: "Parent mobile 1",
      dataIndex: "parent_mobile",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile || null,
      ...getColumnSearchProps("parent_mobile", "parent mobile"),
      editTable: false,
    },
    {
      title: "Parent mobile 2",
      dataIndex: "parent_mobile2",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile2 || null,
      ...getColumnSearchProps("parent_mobile2", "parent mobile 2"),
      editTable: false,
    },
    {
      title: "Section",
      dataIndex: "section",
      filteredValue: state.filteredInfo.section || null,
      ...getColumnSearchProps("section", "Section"),
      editTable: false,
      width: 110,
      align: "center",
      sorter: (a, b) => a.section.length - b.section.length,
      sortOrder:
        state.sortingOptions.columnKey === "section" &&
        state.sortingOptions.order,
    },
    {
      title: "Area",
      dataIndex: "area",
      filteredValue: state.filteredInfo.area || null,
      ...getColumnSearchProps("area", "Area"),
      editTable: false,
      width: 100,
      align: "center",
      sorter: (a, b) => a.area.length - b.area.length,
      sortOrder:
        state.sortingOptions.columnKey === "area" && state.sortingOptions.order,
    },
    {
      title: "School",
      dataIndex: "school",
      filteredValue: state.filteredInfo.school || null,
      ...getColumnSearchProps("school", "School"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.school.length - b.school.length,
      sortOrder:
        state.sortingOptions.columnKey === "school" &&
        state.sortingOptions.order,
    },
    {
      title: "Father Job",
      dataIndex: "parent_job",
      filteredValue: state.filteredInfo.parent_job || null,
      ...getColumnSearchProps("parent_job", "Father Job"),
      editTable: false,
      width: 140,
      align: "center",
      sorter: (a, b) => a.parent_job.length - b.parent_job.length,
      sortOrder:
        state.sortingOptions.columnKey === "parent_job" &&
        state.sortingOptions.order,
    },

    {
      title: "Chances",
      dataIndex: "chances",
      filteredValue: state.filteredInfo.chances || null,
      ...getColumnSearchProps("chances", "Chances"),
      editTable: false,
      width: 110,
      align: "center",
      sorter: (a, b) => a.chances - b.chances,
      sortOrder:
        state.sortingOptions.columnKey === "chances" &&
        state.sortingOptions.order,
    },
    {
      title: "Course permissions",
      dataIndex: "course_permissions",
      filteredValue: state.filteredInfo.course_permissions || null,
      ...getColumnSearchProps("course_permissions", "Course permissions"),
      editTable: false,
      width: 170,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "course_permissions" &&
        state.sortingOptions.order,
    },

    {
      title: "Year",
      dataIndex: "year",
      editTable: false,
      width: 150,
      align: "center",
    },
    {
      title: "approved by",
      dataIndex: "approved_by",
      filteredValue: state.filteredInfo.approved_by || null,
      ...getColumnSearchProps("approved_by", "Approved by"),
      editTable: false,
      width: 140,
      align: "center",
      sorter: (a, b) => a.approved_by.length - b.approved_by.length,
      sortOrder:
        state.sortingOptions.columnKey === "approved_by" &&
        state.sortingOptions.order,
    },
    {
      title: "Photo",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (_, record) => (
        <div className="profile_pic h-[74px] w-[74px] overflow-hidden rounded-full border-2 border-secondary p-1">
          <img
            className="h-full w-full rounded-full object-cover object-top"
            src={record.profile_pic}
            alt={record.name}
          />
        </div>
      ),
    },
    {
      title: "Options",
      dataIndex: "",
      width: 350,
      align: "center",
      fixed: state.smallWindow ? false : "right",

      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-4">
            <>
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_send_message")
                )) && (
                <button
                  onClick={() =>
                    navigate(`/admin/students/send-message/${record.key}`)
                  }
                  className="w-[50px] flex items-center justify-center rounded-full border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                >
                  <BsMessenger />
                </button>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("send_whatsapp")
                )) && (
                <button
                  onClick={() =>
                    navigate(`/admin/students/send-whatsapp/${record.key}`)
                  }
                  className="w-[50px] flex items-center justify-center rounded-full border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                >
                  <BsWhatsapp />
                </button>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_view")
                )) && (
                <button
                  onClick={() =>
                    navigate(`/admin/students/view-student/${record.key}`)
                  }
                  className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                >
                  View
                </button>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_edit")
                )) && (
                <button
                  onClick={() =>
                    navigate(`/admin/students/edit-student/${record.key}`)
                  }
                  className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                >
                  Edit
                </button>
              )}

              {record.is_login > 0 &&
                (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("student_edit")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#ffffff"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleLogutStudent(record)}
                    title="Are you sure you want to Logout This Student?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90">
                      Logout
                    </button>
                  </Popconfirm>
                )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_delete")
                )) && (
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleDelete(record)}
                  title="Are you sure you want to delete?"
                >
                  <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90 ">
                    Delete
                  </button>
                </Popconfirm>
              )}
            </>
          </div>
        ) : null;
      },
    },
  ];
  const deletedStudentsColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,
      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },

    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      fixed: state.smallWindow ? false : "left",

      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      filteredValue: state.filteredInfo.email || null,
      ...getColumnSearchProps("email", "E-mail"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.email.length - b.email.length,
      sortOrder:
        state.sortingOptions.columnKey === "email" &&
        state.sortingOptions.order,
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,
      ...getColumnSearchProps("mobile", "Phone Number"),
      editTable: false,
    },
    {
      title: "Parent mobile 1",
      dataIndex: "parent_mobile",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile || null,
      ...getColumnSearchProps("parent_mobile", "parent mobile"),
      editTable: false,
    },
    {
      title: "Parent mobile 2",
      dataIndex: "parent_mobile2",
      width: 150,
      align: "center",
      filteredValue: state.filteredInfo.parent_mobile2 || null,
      ...getColumnSearchProps("parent_mobile2", "parent mobile 2"),
      editTable: false,
    },
    {
      title: "Section",
      dataIndex: "section",
      filteredValue: state.filteredInfo.section || null,
      ...getColumnSearchProps("section", "Section"),
      editTable: false,
      width: 110,
      align: "center",
      sorter: (a, b) => a.section.length - b.section.length,
      sortOrder:
        state.sortingOptions.columnKey === "section" &&
        state.sortingOptions.order,
    },
    {
      title: "Area",
      dataIndex: "area",
      filteredValue: state.filteredInfo.area || null,
      ...getColumnSearchProps("area", "Area"),
      editTable: false,
      width: 100,
      align: "center",
      sorter: (a, b) => a.area.length - b.area.length,
      sortOrder:
        state.sortingOptions.columnKey === "area" && state.sortingOptions.order,
    },
    {
      title: "School",
      dataIndex: "school",
      filteredValue: state.filteredInfo.school || null,
      ...getColumnSearchProps("school", "School"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.school.length - b.school.length,
      sortOrder:
        state.sortingOptions.columnKey === "school" &&
        state.sortingOptions.order,
    },
    {
      title: "Father Job",
      dataIndex: "parent_job",
      filteredValue: state.filteredInfo.parent_job || null,
      ...getColumnSearchProps("parent_job", "Father Job"),
      editTable: false,
      width: 140,
      align: "center",
      sorter: (a, b) => a.parent_job.length - b.parent_job.length,
      sortOrder:
        state.sortingOptions.columnKey === "parent_job" &&
        state.sortingOptions.order,
    },

    {
      title: "Chances",
      dataIndex: "chances",
      filteredValue: state.filteredInfo.chances || null,
      ...getColumnSearchProps("chances", "Chances"),
      editTable: false,
      width: 110,
      align: "center",
      sorter: (a, b) => a.chances - b.chances,
      sortOrder:
        state.sortingOptions.columnKey === "chances" &&
        state.sortingOptions.order,
    },
    {
      title: "Course permissions",
      dataIndex: "course_permissions",
      filteredValue: state.filteredInfo.course_permissions || null,
      ...getColumnSearchProps("course_permissions", "Course permissions"),
      editTable: false,
      width: 170,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "course_permissions" &&
        state.sortingOptions.order,
    },

    {
      title: "Activation Code",
      dataIndex: "activation_code",
      filteredValue: state.filteredInfo.activation_code || null,
      ...getColumnSearchProps("activation_code", "Activation Code"),
      editTable: false,
      width: 150,
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "activation_code" &&
        state.sortingOptions.order,
    },
    {
      title: "approved by",
      dataIndex: "approved_by",
      filteredValue: state.filteredInfo.approved_by || null,
      ...getColumnSearchProps("approved_by", "Approved by"),
      editTable: false,
      width: 140,
      align: "center",
      sorter: (a, b) => a.approved_by.length - b.approved_by.length,
      sortOrder:
        state.sortingOptions.columnKey === "approved_by" &&
        state.sortingOptions.order,
    },
    {
      title: "Photo",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (_, record) => (
        <div className="profile_pic h-[74px] w-[74px] overflow-hidden rounded-full border-2 border-secondary p-1">
          <img
            className="h-full w-full rounded-full object-cover object-top"
            src={record.profile_pic}
            alt={record.name}
          />
        </div>
      ),
    },
    {
      title: "Options",
      dataIndex: "",
      width: 190,
      align: "center",
      fixed: state.smallWindow ? false : "right",

      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-6">
            <>
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request_accept")
                )) && (
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleDelete(record)}
                  title="Are you sure you want to delete?"
                >
                  <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90 ">
                    Delete
                  </button>
                </Popconfirm>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request_accept")
                )) && (
                <button
                  onClick={() => handleRestore(record)}
                  className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                >
                  Restore
                </button>
              )}
            </>
          </div>
        ) : null;
      },
    },
  ];

  const assistantsColumns = [
    {
      title: "Numbering",
      dataIndex: "n",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,
      ...getColumnSearchProps("n", "Numbering"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },
    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      /**
      *  ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
      */
      //responsive: ["lg"]
      ...getColumnSearchProps("name", "Name"),
      editTable: false,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      width: 120,
      align: "center",
      filteredValue: state.filteredInfo.mobile || null,
      ...getColumnSearchProps("mobile", "Phonr Number"),
      editTable: false,
    },
    {
      title: "Photo",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (_, record) => (
        <div className="profile_pic h-[74px] w-[74px] overflow-hidden rounded-full border-2 border-secondary p-1">
          <img
            className="h-full w-full rounded-full object-cover object-top"
            src={record.profile_pic}
            alt={record.name}
          />
        </div>
      ),
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            <button
              className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
              onClick={() =>
                navigate(`/admin/assistants/edit-assistant/${record.key}`)
              }
            >
              Edit
            </button>
            <Popconfirm
              okText="Confirm"
              okType="danger"
              cancelText="Cancel"
              color="#ffffff"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleDelete(record)}
              title="Are you sure you want to delete?"
            >
              <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                Delete
              </button>
            </Popconfirm>
          </div>
        ) : null;
      },
    },
  ];
  const setsColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,
      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },

    {
      title: "Name",
      dataIndex: "category_name",
      filteredValue: state.filteredInfo.category_name || null,
      fixed: state.smallWindow ? false : "left",

      ...getColumnSearchProps("category_name", "Name"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.category_name.length - b.category_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      ...getColumnSearchProps("easy", "Easy"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      ...getColumnSearchProps("medium", "Medium"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },

    {
      title: "Hard",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      ...getColumnSearchProps("hard", "Hard"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },

    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "right",

      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_quizzes_permissions")
                  ) ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_homeworks_permissions")
                  )) && (
                  <>
                    <button
                      className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                      onClick={() => handleEdit(record)}
                    >
                      Edit
                    </button>

                    <Popconfirm
                      okText="Confirm"
                      okType="danger"
                      cancelText="Cancel"
                      color="#ffffff"
                      //okButtonProps={{ loading: state.loading }}
                      onConfirm={() => handleDelete(record)}
                      title="Are you sure you want to delete?"
                    >
                      <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                        Delete
                      </button>
                    </Popconfirm>
                  </>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm Cancel Editing"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-red-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const examSetsColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,
      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },

    {
      title: "Name",
      dataIndex: "category_name",
      filteredValue: state.filteredInfo.category_name || null,
      fixed: state.smallWindow ? false : "left",

      ...getColumnSearchProps("category_name", "Name"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.category_name.length - b.category_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Easy",
      dataIndex: "easy",
      filteredValue: state.filteredInfo.easy || null,
      ...getColumnSearchProps("easy", "Easy"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.easy - b.easy,
      sortOrder:
        state.sortingOptions.columnKey === "easy" && state.sortingOptions.order,
    },
    {
      title: "Medium",
      dataIndex: "medium",
      filteredValue: state.filteredInfo.medium || null,
      ...getColumnSearchProps("medium", "Medium"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.medium - b.medium,
      sortOrder:
        state.sortingOptions.columnKey === "medium" &&
        state.sortingOptions.order,
    },

    {
      title: "Hard",
      dataIndex: "hard",
      filteredValue: state.filteredInfo.hard || null,
      ...getColumnSearchProps("hard", "Hard"),
      editTable: true,
      width: 100,
      align: "center",
      sorter: (a, b) => a.hard - b.hard,
      sortOrder:
        state.sortingOptions.columnKey === "hard" && state.sortingOptions.order,
    },

    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "right",

      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_quizzes_permissions")
                  ) ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("lecture_homeworks_permissions")
                  )) && (
                  <>
                    <button
                      className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                      onClick={() => handleEdit(record)}
                    >
                      Edit
                    </button>

                    <Popconfirm
                      okText="Confirm"
                      okType="danger"
                      cancelText="Cancel"
                      color="#ffffff"
                      //okButtonProps={{ loading: state.loading }}
                      onConfirm={() => handleDelete(record)}
                      title="Are you sure you want to delete?"
                    >
                      <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                        Delete
                      </button>
                    </Popconfirm>
                  </>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Confirm Cancel Editing"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-red-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];
  const OfferQuestionsColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 110,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,
      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },

    {
      title: "Course Name",
      dataIndex: "course_name",
      filteredValue: state.filteredInfo.course_name || null,
      fixed: state.smallWindow ? false : "left",

      ...getColumnSearchProps("course_name", "Name"),
      editTable: false,
      width: 200,
      align: "center",
      sorter: (a, b) => a.course_name.length - b.course_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "course_name" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 150,
      align: "center",
      fixed: state.smallWindow ? false : "right",

      render: (_, record) => {
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("offer_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#ffffff"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="Are you sure you want to delete?"
              >
                <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const viewCourseColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },

    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("name", "Name"),
      editTable: true,
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      editTable: true,
      width: 150,
      align: "center",
    },
    {
      title: "Parent Mobile",
      dataIndex: "parent_mobile",
      width: 200,
      align: "center",
    },
    {
      title: "Parent Mobile 2",
      dataIndex: "parent_mobile2",
      width: 200,
      align: "center",
    },
  ];
  const viewCourseLateStudentsColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },

    {
      title: "Name",
      dataIndex: "name",
      filteredValue: state.filteredInfo.name || null,
      ...getColumnSearchProps("name", "Name"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder:
        state.sortingOptions.columnKey === "name" && state.sortingOptions.order,
    },
    {
      title: "Student Number",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,
      ...getColumnSearchProps("mobile", "Student Number"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
      sortOrder:
        state.sortingOptions.columnKey === "mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Parent Number 1",
      dataIndex: "parent_mobile",
      filteredValue: state.filteredInfo.parent_mobile || null,
      ...getColumnSearchProps("parent_mobile", "Parent Number 1"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.parent_mobile.length - b.parent_mobile.length,
      sortOrder:
        state.sortingOptions.columnKey === "parent_mobile" &&
        state.sortingOptions.order,
    },
    {
      title: "Parent Number 2",
      dataIndex: "parent_mobile2",
      filteredValue: state.filteredInfo.parent_mobile2 || null,
      ...getColumnSearchProps("parent_mobile2", "Parent Number 2"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.parent_mobile2.length - b.parent_mobile2.length,
      sortOrder:
        state.sortingOptions.columnKey === "parent_mobile2" &&
        state.sortingOptions.order,
    },
  ];

  const analyticsColumns = [
    {
      title: "Name",
      dataIndex: "teacher_name",
      filteredValue: state.filteredInfo.teacher_name || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("teacher_name", "teacher_name"),
      editTable: false,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sortOrder:
        state.sortingOptions.columnKey === "teacher_name" &&
        state.sortingOptions.order,
    },
    {
      title: "Photo",
      dataIndex: "teacher_name",
      width: 70,
      align: "center",
      render: (_, record) => (
        <div className="profile_pic h-[74px] w-[74px] overflow-hidden rounded-full border-2 border-secondary p-1">
          <img
            className="h-full w-full rounded-full object-cover object-top"
            src={record.teacher_img}
            alt={record.name}
          />
        </div>
      ),
    },
    {
      title: "Unreaded Messages",
      dataIndex: "number_message",
      // filteredValue: state.filteredInfo.number_message || null,
      ...getColumnSearchProps("number_message", "number_message"),
      width: 70,
      align: "center",
      sorter: (a, b) => a.number_message - b.number_message,
      sortOrder:
        state.sortingOptions.columnKey === "number_message" &&
        state.sortingOptions.order,
    },
    {
      title: "Lst Replied Message",
      dataIndex: "from",
      // filteredValue: state.filteredInfo.from || null,
      ...getColumnSearchProps("from", "from"),
      width: 70,
      align: "center",
      sorter: (a, b) => a.from - b.from,
      sortOrder:
        state.sortingOptions.columnKey === "from" && state.sortingOptions.order,
    },
  ];

  const vodafoneCashColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },
    {
      title: "ID",
      dataIndex: "key",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("key", "key"),
      sorter: (a, b) => a.n - b.n,
      sortOrder:
        state.sortingOptions.columnKey === "n" && state.sortingOptions.order,
    },

    {
      title: "Code",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Code"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },
    {
      title: "Price",
      dataIndex: "price",
      filteredValue: state.filteredInfo.price || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("price", "Price"),
      editTable: true,
      width: 40,
      align: "center",
      sorter: (a, b) => a.price - b.price,
      sortOrder:
        state.sortingOptions.columnKey === "price" &&
        state.sortingOptions.order,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      filteredValue: state.filteredInfo.created_at || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("created_at", "Created At"),
      width: 60,
      align: "center",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortOrder:
        state.sortingOptions.columnKey === "created_at" &&
        state.sortingOptions.order,
    },
    {
      title: "Options",
      dataIndex: "",
      width: 70,
      align: "center",
      fixed: state.smallWindow ? false : "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return state.data.length > 0 ? (
          <div className="m-0 flex  justify-center gap-10">
            {!editable ? (
              <>
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_delete_vodafone")
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#ffffff"
                    //okButtonProps={{ loading: state.loading }}
                    onConfirm={() => handleDelete(record)}
                    title="Are you sure you want to delete?"
                  >
                    <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith("code_update_vodafone")
                  )) && (
                  <button
                    className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={handleCancelEdit}
                  title="Are you sure you want to cancel?"
                >
                  <button className="w-20 rounded-2xl border-2 border-red-400 bg-red-400 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-red-400 active:scale-90">
                    Cancel
                  </button>
                </Popconfirm>
                <button
                  className="w-20 rounded-2xl border-2 border-save bg-save py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-save active:scale-90"
                  onClick={() => handleSaveEdit(record)}
                >
                  Save
                </button>
              </>
            )}
          </div>
        ) : null;
      },
    },
  ];

  const purchaseHistoryColumns = [
    {
      title: "Number",
      dataIndex: "n",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      filteredValue: state.filteredInfo.n || null,

      ...getColumnSearchProps("n", "Number"),
      sorter: (a, b) => a.key - b.key,
      sortOrder:
        state.sortingOptions.columnKey === "key" && state.sortingOptions.order,
    },
    {
      title: "Course",
      dataIndex: "course",
      filteredValue: state.filteredInfo.course || null,
      width: 60,
      align: "center",
    },
    {
      title: "Code",
      dataIndex: "code",
      filteredValue: state.filteredInfo.code || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("code", "Code"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder:
        state.sortingOptions.columnKey === "code" && state.sortingOptions.order,
    },
    {
      title: "Price",
      dataIndex: "price",
      filteredValue: state.filteredInfo.price || null,
      width: 40,
      align: "center",
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Buy At",
      dataIndex: "buy_at",
      filteredValue: state.filteredInfo.buy_at || null,
      width: 60,
      align: "center",
    },
    {
      title: "Expire At",
      dataIndex: "expire_at",
      filteredValue: state.filteredInfo.expire_at || null,
      width: 60,
      align: "center",
    },
  ];

  const adminViewStudentAsignmentsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      ...getColumnSearchProps("name", "name"),
    },

    {
      title: "Type",
      dataIndex: "type",
      filteredValue: state.filteredInfo.type || null,
      ...getColumnSearchProps("type", "type"),
      editTable: true,
      width: 70,
      fixed: state.smallWindow ? false : "left",
      align: "center",
    },
    {
      title: "Degree",
      dataIndex: "degree",
      filteredValue: state.filteredInfo.degree || null,

      ...getColumnSearchProps("degree", "degree"),
      editTable: true,
      width: 40,
      align: "center",
      sorter: (a, b) => a.degree - b.degree,
      sortOrder:
        state.sortingOptions.columnKey === "degree" &&
        state.sortingOptions.order,
    },
    {
      title: "Out of",
      dataIndex: "out_of",
      filteredValue: state.filteredInfo.out_of || null,

      ...getColumnSearchProps("out_of", "out_of"),
      editTable: true,
      width: 40,
      align: "center",
      sorter: (a, b) => a.out_of - b.out_of,
      sortOrder:
        state.sortingOptions.columnKey === "out_of" &&
        state.sortingOptions.order,
    },
  ];

  const viewAllStudentsAssignmentsColumns = [
    {
      title: "Name",
      dataIndex: "student_name",
      editTable: false,
      width: 100,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      ...getColumnSearchProps("student_name", "student_name"),
    },
    {
      title: "",
      dataIndex: "0",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (text, record, rowIndex) => {
        return <span>{record.quiz[0]?.name}</span>;
      },
    },
    {
      title: "",
      dataIndex: "1",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (text, record, rowIndex) => {
        return <span>{record.quiz[1]?.name}</span>;
      },
    },
    {
      title: "",
      dataIndex: "2",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (text, record, rowIndex) => {
        return <span>{record.quiz[2]?.name}</span>;
      },
    },
    {
      title: "",
      dataIndex: "3",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (text, record, rowIndex) => {
        return <span>{record?.quiz[3]?.name}</span>;
      },
    },
    {
      title: "",
      dataIndex: "4",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (text, record, rowIndex) => {
        return <span>{record?.quiz[4]?.name}</span>;
      },
    },
    {
      title: "",
      dataIndex: "5",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (text, record, rowIndex) => {
        return <span>{record?.quiz[5]?.name}</span>;
      },
    },
    {
      title: "",
      dataIndex: "6",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (text, record, rowIndex) => {
        return (
          <span>
            {record?.quiz[6]?.name}
            {}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "7",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (text, record, rowIndex) => {
        return <span>{record?.quiz[7]?.name}</span>;
      },
    },
    {
      title: "",
      dataIndex: "8",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (text, record, rowIndex) => {
        return <span>{record?.quiz[8]?.name}</span>;
      },
    },
    {
      title: "",
      dataIndex: "9",
      editTable: false,
      width: 50,
      align: "center",
      fixed: state.smallWindow ? false : "left",
      render: (text, record, rowIndex) => {
        return <span>{record?.quiz[9]?.name}</span>;
      },
    },
  ];

  let viewAllStudentsReportsColumns = [];

  if (tableFor === "viewAllStudentsReports") {
    viewAllStudentsReportsColumns =
      state?.data?.length !== 0
        ? Object.keys(state?.data[0]).map((key) => ({
            [key]: state?.data[0][key],
            title: key,
            dataIndex: key,
            width: 200,
            align: "center",
          }))
        : [];
  }

  let viewAllLateStudentsColumns = [];

  if (tableFor === "viewAllLateStudents" && externalData?.length > 0) {
    viewAllLateStudentsColumns =
      state?.data?.length !== 0 && externalData?.length > 0
        ? Object.keys(externalData[0]).map((key) => ({
            [key]: externalData[0][key],
            title: key,
            dataIndex: key,
            width: 200,
            align: "center",
          }))
        : [];
  }

  const viewStudentExamColumns = [
    {
      title: "Number",
      dataIndex: "n",
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      ...getColumnSearchProps("n", "Number"),
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      filteredValue: state.filteredInfo.student_name || null,

      //responsive: ["lg"],
      ...getColumnSearchProps("student_name", "Student Name"),
      width: 200,
      fixed: state.smallWindow ? false : "left",
      align: "center",
      sorter: (a, b) => a.student_name.length - b.student_name.length,
      sortOrder:
        state.sortingOptions.columnKey === "student_name" &&
        state.sortingOptions.order,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      filteredValue: state.filteredInfo.mobile || null,
      //responsive: ["lg"],
      ...getColumnSearchProps("mobile", "Mobile"),
      width: 150,
      align: "center",
    },
    {
      title: "Parent Mobile 1",
      dataIndex: "parent_mobile",
      width: 150,
      align: "center",
    },
    {
      title: "Parent Mobile 2",
      dataIndex: "parent_mobile2",
      width: 150,
      align: "center",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      width: 150,
      align: "center",
      render: (_, record) => (
        <p>{record.percentage ? `${record.percentage}%` : null}</p>
      ),
    },
    {
      title: "submitted At",
      dataIndex: "created_at",
      width: 150,
      align: "center",
    },
    {
      title: "Degree",
      dataIndex: "degree",
      width: 150,
      align: "center",
    },

    {
      title: "Options",
      dataIndex: "",
      width: 270,
      align: "center",
      render: (_, record) => {
        return record.key !== null ? (
          <div className="m-0 w-full flex justify-center gap-6">
            {/* <Popconfirm
              okText="Confirm"
              okType="danger"
              cancelText="Cancel"
              color="#ffffff"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleDelete(record)}
              title="Confirm Delete Exam?"
            >
              <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90 ">
                Delete
              </button>
            </Popconfirm> */}
            <Popconfirm
              okText="Confirm"
              okType="danger"
              cancelText="Cancel"
              color="#ffffff"
              //okButtonProps={{ loading: state.loading }}
              onConfirm={() => handleRetakeExam(record)}
              title="confirm Retake Exam?"
            >
              <button className="px-4 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90">
                Retake Exam
              </button>
            </Popconfirm>
          </div>
        ) : null;
      },
    },
  ];

  const logsColumns = [
    {
      title: "Log",
      dataIndex: "log",
      width: 200,
      align: "center",
    },
    {
      title: "Time",
      dataIndex: "at",
      filteredValue: state.filteredInfo.mobile || null,
      width: 150,
      align: "center",
    },
  ];
  const purchaseHistoryStudentViewColumns = [
    {
      title: "course",
      dataIndex: "course",
      width: 200,
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 100,
      align: "center",
    },
    {
      title: "code",
      dataIndex: "code",
      width: 150,
      align: "center",
    },
    {
      title: "price",
      dataIndex: "price",
      width: 150,
      align: "center",
    },
    {
      title: "Buy At",
      dataIndex: "buy_at",
      width: 150,
      align: "center",
    },
    {
      title: "Expire At",
      dataIndex: "expire_at",
      width: 150,
      align: "center",
    },
    {
      title: "Add by",
      dataIndex: "add_by",
      width: 150,
      align: "center",
    },
  ];
  const trialExamColumns = [
    {
      title: "Student Name",
      dataIndex: "student_name",
      width: 200,
      align: "center",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 150,
      align: "center",
    },
    {
      title: "parent mobile",
      dataIndex: "parent_mobile",
      width: 150,
      align: "center",
    },
    {
      title: "parent mobile2",
      dataIndex: "parent_mobile2",
      width: 150,
      align: "center",
    },
    {
      title: "percentage",
      dataIndex: "percentage",
      width: 150,
      align: "center",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      width: 150,
      align: "center",
    },
    {
      title: "Degree",
      dataIndex: "degree",
      width: 150,
      align: "center",
    },
  ];
  //!----------Nested Table for attendance page

  const expandedRow = (row) => {
    const nestedViewCourseColumns = [
      {
        title: "Number",
        dataIndex: "n",
        width: 30,
        align: "center",
        filteredValue: state.filteredInfo.n || null,
        editTable: false,

        ...getColumnSearchProps("n", "Number"),
        sorter: (a, b) => a.n - b.n,
        sortOrder:
          state.sortingOptions.columnKey === "n" && state.sortingOptions.n,
      },
      {
        title: "Buy At",
        dataIndex: "buy_at",
        width: 200,
        align: "center",
        filteredValue: state.filteredInfo.buy_at || null,

        ...getColumnSearchProps("buy_at", "Buied At"),
        sorter: (a, b) => a.buy_at - b.buy_at,
        sortOrder:
          state.sortingOptions.columnKey === "buy_at" &&
          state.sortingOptions.order,
      },
      {
        title: "Expire At",
        dataIndex: "expire_at",
        filteredValue: state.filteredInfo.expire_at || null,
        ...getColumnSearchProps("expire_at", "Expire At"),
        editTable: true,
        width: 200,
        fixed: state.smallWindow ? false : "right",
        align: "center",
        sorter: (a, b) => a.expire_at.length - b.expire_at.length,
        sortOrder:
          state.sortingOptions.columnKey === "expire_at" &&
          state.sortingOptions.order,
      },
      {
        title: "Options",
        dataIndex: "Options",
        width: 200,
        fixed: state.smallWindow ? false : "right",
        align: "center",
        render: (_, record) => {
          return state.data.length > 0 ? (
            <div className="m-0 flex  justify-center gap-4">
              <Link
                to={`/admin/courses/view/view-asignments/${record.id}`}
                className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
              >
                View
              </Link>
              <Link
                to={`/admin/courses/view/edit/${record.id}`}
                className="w-20 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-accent active:scale-90"
              >
                Edit
              </Link>
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#ffffff"
                //okButtonProps={{ loading: state.loading }}
                onConfirm={() => handleDelete(record)}
                title="Are you sure you want to delete?"
              >
                <button className="w-20 rounded-2xl border-2 border-delete bg-delete py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-delete active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            </div>
          ) : null;
        },
      },
    ];

    let nestedData;
    nestedData = row?.purchases;

    return (
      <Table
        columns={nestedViewCourseColumns}
        dataSource={nestedData}
        pagination={false}
      />
    );
  };

  switch (tableFor) {
    case "ns":
      columns = codesColumns;
      break;
    case "activationCodes":
      columns = activationCodesColumns;
      break;

    case "offersCodes":
      columns = offersCodesColumns;
      break;
    case "pointsCodes":
      columns = pointsCodesColumns;
      break;

    case "QuestionBank":
      columns = QuestionBankColumns;
      break;
    case "attendance":
      columns = AttendanceColumns;
      break;
    case "session":
      columns = sessionColumns;
      break;
    case "assistants":
      columns = assistantsColumns;
      break;
    case "students":
      columns = studentsColumns;
      break;
    case "deletedStudents":
      columns = deletedStudentsColumns;
      break;
    case "sets":
      columns = setsColumns;
      break;
    case "examSets":
      columns = examSetsColumns;
      break;
    case "OfferQuestions":
      columns = OfferQuestionsColumns;
      break;
    case "viewCourse":
      columns = viewCourseColumns;
      break;
    case "viewCourseLateStudents":
      columns = viewCourseLateStudentsColumns;
      break;
    case "analytics":
      columns = analyticsColumns;
      break;
    case "vodafoneCash":
      columns = vodafoneCashColumns;
      break;
    case "purchaseHistory":
      columns = purchaseHistoryColumns;
      break;
    case "adminViewStudentAsignments":
      columns = adminViewStudentAsignmentsColumns;
      break;
    case "viewAllStudentsAssignments":
      columns = viewAllStudentsAssignmentsColumns;
      break;
    case "viewAllStudentsReports":
      columns = viewAllStudentsReportsColumns;
      break;
    case "viewAllLateStudents":
      columns = viewAllLateStudentsColumns;
      break;
    case "viewStudentExam":
      columns = viewStudentExamColumns;
      break;
    case "logs":
      columns = logsColumns;
      break;
    case "purchaseHistoryStudentView":
      columns = purchaseHistoryStudentViewColumns;
      break;
    case "trialExam":
      columns = trialExamColumns;
      break;
    default:
      columns = codesColumns;
      break;
  }

  const editableColumns = columns?.map((col) => {
    if (!col.editTable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // const [xData, setXData] = useState([]);

  // console.log(
  //   setXData([
  //   state?.data?.map((student) =>
  //       {...student,
  //       {
  //         student_name: student.student_name,
  //         ...student.quiz?.map((quiz) => quiz),
  //       },}
  //     )
  //   )]
  // );

  const table = (
    <section>
      <div className="table-options mt-12 mb-9 flex items-center justify-between gap-10  md:flex-col md:items-center">
        <div className="search-wrapper w-1/2 md:w-full">
          <input
            type="search"
            className="signin-inputs px-4 placeholder:!text-base"
            placeholder="Search..."
            onChange={handleGlobalSearch}
            value={state.query}
          />
        </div>
        <div className="export-reset flex w-1/2 items-center justify-end gap-10 md:w-full md:justify-evenly">
          {state?.data && (
            <button className="bg-accenbgt w-fit rounded-2xl border-2 border-blue px-3 py-1 text-center font-semibold text-blue duration-300 hover:bg-blue hover:text-bg active:scale-90">
              <CSVLink data={state?.data} filename={`${tableFor} excel.csv`}>
                Export
              </CSVLink>
            </button>
          )}
          <button
            className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-secondary active:scale-90"
            onClick={handleResetSortFilterSearch}
          >
            Reset
          </button>
        </div>
      </div>

      <div className=" w-full  overflow-hidden rounded-xl border-2  border-secondary">
        <Form form={form} component={false}>
          <Table
            columns={editableColumns}
            dataSource={state.data}
            components={{ body: { cell: editableCell } }}
            bordered
            onChange={handleTableChange}
            loading={state.loading}
            expandedRowRender={nested ? expandedRow : ""}
            pagination={{ position: ["bottomCenter"] }}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: state.selectedRows,
              onChange: (keys, records) => {
                dispatch({
                  type: "setSelectedRows",
                  payload: keys,
                });
                handelSelectionItems(records);
              },
              selections: [
                Table.SELECTION_ALL,
                Table.SELECTION_NONE,
                Table.SELECTION_INVERT,
                {
                  key: "even",
                  text: "Select Even Rows",
                  onSelect: (allKeys, records) => {
                    const selectedKeys = allKeys.filter((key) => key % 2 === 0);
                    dispatch({
                      type: "setSelectedRows",
                      payload: selectedKeys,
                    });
                  },
                },
                {
                  key: "odd",
                  text: "Select Odd Rows",
                  onSelect: (allKeys) => {
                    const selectedKeys = allKeys.filter((key) => key % 2 !== 0);

                    dispatch({
                      type: "setSelectedRows",
                      payload: selectedKeys,
                    });
                  },
                },
                {
                  key: "First Level",
                  text: "Select First Level Students",
                  onSelect: (allKeys) => {
                    const selectedKeys = allKeys.filter((key) => {
                      const matched = state.data.find((item) => {
                        return (
                          item.key === key && item.academiclevel === "First"
                        );
                      });
                      return matched;
                    });

                    dispatch({
                      type: "setSelectedRows",
                      payload: selectedKeys,
                    });
                  },
                },
              ],
            }}
            scroll={{
              y: 540,
            }}

            /**expandable={{
              expandedRowRender: (record) => <p> {record.school}</p>,
              rowExpandable: (record) => record.school !== "Not Expandable",
            }}*/
          />
        </Form>
      </div>
    </section>
  );
  return table;
}

export default useTable;
