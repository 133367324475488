import DropDownMenu from "./DropDownMenu";
import { NavLink, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthState } from "./GlobalContext.jsx";

function Navbar() {
  const AuthState = useAuthState();
  const [scroll, setScroll] = useState(false);
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 40) {
        setScroll(true);
      } else setScroll(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const activeLink = ({ isActive }) => ({
    background: isActive
      ? "linear-gradient(94deg, rgba(3, 65, 221,1) 0%, rgba(3, 65, 221,1) 100%)"
      : "",
    backgroundSize: isActive ? "100% 3px" : "",
    backgroundRepeat: isActive ? "no-repeat" : "",
    backgroundPosition: isActive ? "left bottom" : "",
  });
  return (
    <>
      {/**
       * //!fixed mobile menu
       */}
      <div
        className={` bg-green fixed right-0 top-0  w-full h-screen flex flex-col items-center justify-center  gap-10 transition-all duration-700 ease-in-out ${
          toggle
            ? "opacity-100 translate-x-0  z-[100]"
            : "opacity-0 translate-x-40 z-[-10]"
        }`}
      >
        <FontAwesomeIcon
          onClick={() => setToggle(!toggle)}
          className="cursor-pointer hover:scale-110 active:scale-90 w-9 h-9 transition-all duration-300 ease-in-out"
          icon={faXmark}
        />
        <ul className="  text-lg flex flex-col justify-between items-center gap-12  font-semibold  ">
          {!AuthState.userData?.student_token && (
            <li onClick={() => setToggle(!toggle)}>
              <NavLink
                className="underline-gradient "
                to="/home"
                style={activeLink}
              >
                Home
              </NavLink>
              <a className="underline-gradient " href="#top">
                Home
              </a>
            </li>
          )}
          {!AuthState.userData?.student_token && (
            <li onClick={() => setToggle(!toggle)}>
              <NavLink
                className="underline-gradient "
                to="/Instructor"
                style={activeLink}
              ></NavLink>
              <a className="underline-gradient " href="#Instructor">
                Instructor
              </a>
            </li>
          )}
          <li onClick={() => setToggle(!toggle)}>
            {AuthState.userData?.student_token ? (
              <>
                <NavLink
                  className="underline-gradient "
                  to="/home"
                  style={activeLink}
                >
                  Home
                </NavLink>
              </>
            ) : null}
          </li>
          <li onClick={() => setToggle(!toggle)}>
            {AuthState.userData?.student_token ? (
              <>
                <NavLink
                  className="underline-gradient "
                  to="/courses"
                  style={activeLink}
                >
                  Courses
                </NavLink>
              </>
            ) : (
              <a className="underline-gradient " href="#Courses">
                Courses
              </a>
            )}
          </li>
          <li onClick={() => setToggle(!toggle)}>
            {AuthState.userData?.student_token ? (
              <>
                <NavLink
                  className="underline-gradient "
                  to="/exams"
                  style={activeLink}
                >
                  Exams
                </NavLink>
              </>
            ) : null}
          </li>
          <li onClick={() => setToggle(!toggle)}>
            {AuthState.userData?.student_token ? (
              <>
                <NavLink
                  className="underline-gradient "
                  to="/home/profile"
                  style={activeLink}
                >
                  Profile
                </NavLink>
              </>
            ) : null}
          </li>

          {!AuthState.userData?.student_token && (
            <li onClick={() => setToggle(!toggle)}>
              <NavLink
                className="underline-gradient "
                to="/students"
                style={activeLink}
              ></NavLink>
              <a className="underline-gradient " href="/#Contact">
                Contact
              </a>
            </li>
          )}

          {!AuthState.userData?.student_token && (
            <li onClick={() => setToggle(!toggle)}>
              <NavLink
                className="underline-gradient "
                to="/about"
                style={activeLink}
              ></NavLink>
              <a className="underline-gradient " href="/#About">
                About
              </a>
            </li>
          )}
        </ul>
      </div>
      {/**
       * //!desktop nav
       */}
      <div className={`fixed w-full h-20 flex items-center z-50 `}>
        <nav
          className={`navWidth  duration-500 flex justify-between items-center   ${
            scroll ? "bg-yellow/100 rounded-full translate-y-3 " : "bg-yellow/0"
          }`}
        >
          <Link
            className="cursor-pointer flex justify-center items-center "
            to="/"
          >
            <img
              //!logo
              className="h-[55px] w-auto "
              src="https://api.our-matrix.com/uploads/assets/logo.png"
              alt="logo"
            />
          </Link>

          <div
            //!desktop menu items
            className="md:hidden"
          >
            <ul className="  text-lg flex justify-between items-center  lg:gap-7 gap-12 font-semibold  ">
              <li>
                {AuthState.userData?.student_token ? (
                  <NavLink
                    className="underline-gradient "
                    to="/home"
                    style={activeLink}
                  >
                    Home
                  </NavLink>
                ) : (
                  <a className="underline-gradient " href="#top">
                    Home
                  </a>
                )}
              </li>
              {!AuthState.userData?.student_token && (
                <li>
                  <NavLink
                    className="underline-gradient "
                    to="/Instructor"
                    style={activeLink}
                  ></NavLink>
                  <a className="underline-gradient " href="/#Instructor">
                    Instructor
                  </a>
                </li>
              )}

              <li>
                {AuthState.userData?.student_token ? (
                  <NavLink
                    className="underline-gradient "
                    to="/courses"
                    style={activeLink}
                  >
                    Courses
                  </NavLink>
                ) : (
                  <a className="underline-gradient " href="/#Courses">
                    Courses
                  </a>
                )}
              </li>

              {AuthState.userData?.student_token && (
                <li>
                  <NavLink
                    className="underline-gradient "
                    to="/exams"
                    style={activeLink}
                  >
                    Exams
                  </NavLink>
                </li>
              )}

              {!AuthState.userData?.student_token && (
                <li>
                  <NavLink
                    className="underline-gradient "
                    to="/students"
                    style={activeLink}
                  ></NavLink>
                  <a className="underline-gradient " href="/#Contact">
                    Contact
                  </a>
                </li>
              )}

              {!AuthState.userData?.student_token && (
                <li>
                  <NavLink
                    className="underline-gradient "
                    to="/about"
                    style={activeLink}
                  ></NavLink>
                  <a className="underline-gradient " href="/#About">
                    About
                  </a>
                </li>
              )}
            </ul>
          </div>

          <div className=" hidden md:block">
            <FontAwesomeIcon
              onClick={() => setToggle(!toggle)}
              className="cursor-pointer hover:scale-110 active:scale-90 w-9 h-9 transition-all duration-300 ease-in-out"
              icon={faBars}
            />
          </div>
          {AuthState.userData?.student_token ? (
            <DropDownMenu />
          ) : (
            <>
              <div className="flex justify-center gap-4 ">
                <Link
                  to="/signin"
                  className="sign sm:text-base sm:w-24 sm:h-10 lg:text-base lg:w-24 lg:h-10"
                >
                  Sign In{" "}
                </Link>
                <Link
                  to="/register"
                  className="register lg:hidden lg:text-base lg:w-24 lg:h-10 
            "
                >
                  {" "}
                  Register{" "}
                </Link>
              </div>
            </>
          )}
        </nav>
      </div>
    </>
  );
}

export default Navbar;
