import { DatePicker } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Spin } from "antd";
import toast from "react-hot-toast";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
const { RangePicker } = DatePicker;

export default function WrongQuestions() {
  const [date, setDate] = useState([]);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    depdency: false,
    data: {},
  });

  const [successData, errorData, loadingSuccess] = useAxios(
    process.env.REACT_APP_ANALYTICS_TOP_WRONG_QUESTIONS_API,
    "POST",
    submitAdd.flag,
    submitAdd.depdency,
    submitAdd.data,
    true
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();

  const handleDateChange = (dates, dateString) => {
    if (dates) {
      const formattedDates = dates.map((date) => date.format("YYYY-MM-DD")); // Format to "YYYY-MM-DD"
      setDate(formattedDates);
      setValue("dateRange", formattedDates); // Set for validation
    }
  };

  const onSubmit = (data) => {
    if (!date.length) {
      toast.error("Please select a valid date range.");
      return;
    }

    const [from, to] = date;

    setSubmitAdd({
      flag: "Change Table",
      depdency: !submitAdd.depdency,
      data: {
        from,
        to,
        stage: data.stage,
        limit: data.limit,
      },
    });
  };

  return (
    <div className="px-10 py-10 flex flex-col gap-5 overflow-hidden">
      {/* Form Section */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-1/2 sm:w-full space-y-5 rounded-3xl shadow-2xl p-4"
      >
        {/* Date Range Picker */}
        <RangePicker
          className="signin-inputs"
          onChange={handleDateChange}
          getPopupContainer={(trigger) => trigger.parentNode}
        />
        {errors.dateRange && (
          <p className="text-red-500">{errors.dateRange.message}</p>
        )}

        {/* Stage Selector */}
        <select
          className="signin-inputs"
          {...register("stage", { required: "Stage selection is required" })}
        >
          <option value="">Select a stage</option>
          <option value="1">Stage 1</option>
          <option value="2">Stage 2</option>
          <option value="3">Stage 3</option>
        </select>
        {errors.stage && <p className="text-red-500">{errors.stage.message}</p>}

        {/* Limit Input */}
        <input
          type="number"
          min={1}
          max={50}
          className="signin-inputs"
          placeholder="Limit of questions"
          {...register("limit", {
            required: "Limit is required",
            min: { value: 1, message: "Minimum limit is 1" },
            max: { value: 50, message: "Maximum limit is 50" },
          })}
        />
        {errors.limit && <p className="text-red-500">{errors.limit.message}</p>}

        {/* Submit Button */}
        <button
          type="submit"
          className="submit"
          disabled={loadingSuccess || !isValid}
        >
          {loadingSuccess ? <Spin /> : "Submit"}
        </button>
      </form>
      {successData && successData.data.length > 0 && (
        <button className=" w-[200px] rounded-lg border-2 border-delete bg-delete px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-delete   active:scale-90 ">
          <CSVLink data={successData?.data} filename="Wrong Students">
            Export
          </CSVLink>{" "}
          <FontAwesomeIcon className="ml-2" icon={faDownload} />
        </button>
      )}
      {/* Question Cards */}
      <div className="flex gap-5 flex-wrap w-full">
        {loadingSuccess
          ? null
          : successData &&
            successData.data.map((question, index) => (
              <div
                key={question.id}
                className="w-full max-w-[600px] p-4 rounded-2xl shadow-lg bg-white"
              >
                <div className="flex flex-col gap-3">
                  {/* Question Number */}
                  <p className="text-lg font-bold">Question {index + 1}</p>

                  {/* Question */}
                  <p
                    className="text-gray-700 object-cover "
                    dangerouslySetInnerHTML={{ __html: question.question }}
                  ></p>

                  {/* Answers */}
                  <div className="grid grid-cols-2 gap-3">
                    {["answer1", "answer2", "answer3", "answer4"].map((key) => (
                      <div
                        key={key}
                        className={`p-2 rounded ${
                          question.correct_answer === key
                            ? "bg-green-100 border border-green-500"
                            : "bg-gray-100 border border-gray-300"
                        }`}
                      >
                        <p className="font-semibold">{key.toUpperCase()}</p>
                        <p
                          dangerouslySetInnerHTML={{ __html: question[key] }}
                        ></p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}
